import React from 'react';
import useSWR from 'swr';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const Devices: React.FC = () => {
    const { data, error } = useSWR('https://jsonplaceholder.typicode.com/posts/1', fetcher);

    if (error) return <div>Error loading data</div>;
    if (!data) return <div>Loading...</div>;

    return (
        <div>
            <h2>Devices</h2>
            <p><strong>Title:</strong> {data?.title}</p>
            <p><strong>Body:</strong> {data?.body}</p>
        </div>
    );
};

export default Devices;
