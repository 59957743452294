import { appConfig} from '../data/AppConfig';
import axios from 'axios';
import { useEffect, useState } from 'react';

/**
 * @returns The value of the cookie with the specified name, or `null` if the specified cookie doesn't exist.
 * @example If there's a cookie set that looks like this: MY_SESSION=abcd, then getCookie('MY_SESSION') will return "abcd"
 */
const getCookie = (name: string): string | null => {
    // Split cookie string and get all individual name=value pairs in an array
    let cookieArr = document.cookie.split(';');

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split('=');

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
};

/**
 * Fetches a CSRF token from the backend and stores it in state, making it available for later use.
 */
const useCsrf = () => {
    console.log('useCsrf');
    const [csrfToken, setCsrfToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const refreshCsrfToken = () => {
        console.log('refreshCsrfToken called');
        const abortController = new AbortController();

        setLoading(true);

        const request = axios
            .get(`${appConfig.REACT_APP_BACKEND_URL}/sanctum/csrf-cookie`, {
                signal: abortController.signal,
            })
            .then((response) => {
                const token = getCookie('XSRF-TOKEN');
                console.log('refreshCsrfToken sucess');
                setCsrfToken(token);
                setLoading(false);
                return token;
            })
            .catch(() => {
                console.log('refreshCsrfToken fail');
                setCsrfToken(null);
                setLoading(false);
                return null;
            });

        return { request, abortController };
    };

    useEffect(() => {
        const { abortController } = refreshCsrfToken();

        return () => {
            abortController.abort();
        };
    }, []);

    return {
        csrfToken,
        loading,
        refreshCsrfToken,
    };
};

export { useCsrf };
