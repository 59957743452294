import React from 'react';
import DataFetcher from './DataFetcher';

const Home: React.FC = () => (
    <div>
        <h1>Home Page</h1>
        <DataFetcher />
    </div>
);

export default Home;
