import React from 'react';

const Locations: React.FC = () => (
    <div>
        <h1>Locations</h1>
        <p>This is the about page.</p>
    </div>
);

export default Locations;
