import { Axios, RawAxiosRequestHeaders } from 'axios';
import { appConfig } from './AppConfig';

const defaultHeaders: RawAxiosRequestHeaders = {
    Accept: 'application/json'
};

const apiClient = new Axios({
    baseURL: appConfig.REACT_APP_BACKEND_URL,
    headers: defaultHeaders,
    withCredentials: true,
    validateStatus: (status) => {
        return status < 300;
    },
});

const fetcher = async <ResponsePayload>(
    url: string,
    queryStringParams?: URLSearchParams,
): Promise<ResponsePayload> => {
    return apiClient
        .get(
            `${url}${
                queryStringParams ? '?' + queryStringParams.toString() : ''
            }`,
        )
        .then((response) => JSON.parse(response.data));
};

export { fetcher, apiClient };
