
/**
 * This is unfortunately necessary to make these values accessible during the Zod validation step.
 * NextJS will prune any environment variable values out of process.env if there are no references to
 * it in the codebase.  This means that we can't just expect {...process.env} to provide all env vars
 * at runtime - we need to explicitly reference each env var to prevent them from being pruned.
 */
const appConfig = {
    REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
};

export { appConfig };
