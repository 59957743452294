import React, { useState } from 'react';
import { Button, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import AuthLayout from './AuthLayout';
import { appConfig } from '../../data/AppConfig';
import { useCsrf } from '../../hooks/useCsrf';

const LogInForm = () => {
    const { refreshCsrfToken } = useCsrf();
    const [isLoggingInGoogle, setIsLoggingInGoogle] = useState(false);

    const handleLoginWithGoogle = () => {
        setIsLoggingInGoogle(true);
        refreshCsrfToken().request.then(() => {
            window.location.assign(
                `${appConfig.REACT_APP_BACKEND_URL}/api/auth/redirect/google`,
            );
        });
    };

    return (
        <AuthLayout>
            <Text>
                You can log into Watchly using your Google account.
            </Text>
            <Stack>
                <Button
                    loading={isLoggingInGoogle}
                    onClick={handleLoginWithGoogle}
                >Continue with Google</Button>
            </Stack>
        </AuthLayout>
    );
};

export default LogInForm;
