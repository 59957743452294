import React from 'react';
import { Button, Group, TextInput, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import AuthLayout from "./AuthLayout";

const PasswordRecoveryForm = () => {

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            email: '',
            password: ''
        },
        validate: {
            email: (value: string) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value: string) =>
                /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/.test(value)
                    ? null
                    : 'Password must contain at least one number, one capital letter, and one special character',

        },
    });

    return (
        <AuthLayout>
            <Text>
                Forgot Password
            </Text>
            <Stack>
                <form onSubmit={form.onSubmit((values) => console.log(values))}>
                    <TextInput
                        withAsterisk
                        label="Email"
                        placeholder="your@email.com"
                        key={form.key('email')}
                        {...form.getInputProps('email')}
                    />
                    <Group justify="flex-end" mt="md">
                        <Button type="submit">Send</Button>
                    </Group>
                </form>
            </Stack>
        </AuthLayout>
    );
};

export default PasswordRecoveryForm;
