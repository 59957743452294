import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AppShell, Burger, Group, Menu, ActionIcon, NavLink, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Home from '../components/Home';
import Locations from '../components/Locations';
import LogInForm from '../components/authentication/LogInForm';
import SignUpForm from '../components/authentication/SignUpForm';
import PasswordRecoveryForm from '../components/authentication/PasswordRecoveryForm';
import Devices from '../components/Devices';
import ProtectedRoute from '../components/ProtectedRoute';
import { useAuth } from '../hooks/useAuth';
import { IconHome2, IconBuildings, IconDeviceTv } from '@tabler/icons-react';
import './AppRoutes.css';

const getInitials = (name: string) => {
    // Split the string into words
    const words = name.trim().split(/\s+/);

    // Extract the first letter of the first two words, if available
    const initials = words.slice(0, 2).map(word => word[0].toUpperCase());

    // Join the initials into a string and return
    return initials.join('');
};

export const AppRoutes: React.FC = () => {

    const [opened, { toggle }] = useDisclosure();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/sign-in');
    };

    const handleNav = (route: string) => {
        navigate(route);
    };

    return (
        <AppShell
            header={{height: 60}}
            navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
            padding="md"
        >
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    <Group>
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                        <Text size="xl" fw={700} style={{ fontFamily: "'Caveat', cursive" }}>Watchly</Text>
                    </Group>
                    <Menu shadow="md">
                        <Menu.Target>
                            <ActionIcon variant="filled" radius="xl" aria-label="Settings" p="15">
                                <Text size="sm" fw={700}>{user?.name ? getInitials(user?.name) : 'NA'}</Text>
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Item onClick={handleLogout}>
                                Logout
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </AppShell.Header>
            {user ? (
                <AppShell.Navbar p="md">
                    <Stack
                        h="100%"
                        bg="var(--mantine-color-body)"
                        align="stretch"
                        justify="space-between"
                        gap="md"
                    >
                        <div>
                            <NavLink
                                label="Home"
                                onClick={() => handleNav('/')}
                                leftSection={<IconHome2 size="1rem" stroke={1.5} color="var(--mantine-color-blue-filled)"/>}
                            />
                            <NavLink
                                label="My Locations"
                                onClick={() => handleNav('/locations')}
                                leftSection={<IconBuildings size="1rem" stroke={1.5} color="var(--mantine-color-yellow-filled)"/>}
                            />
                            <NavLink
                                label="Devices"
                                onClick={() => handleNav('/devices')}
                                leftSection={<IconDeviceTv size="1rem" stroke={1.5} color="var(--mantine-color-red-filled)"/>}
                            />
                        </div>
                        <div className={'CopyRightText'}>© Common Software Co. LLC {new Date().getFullYear()}</div>
                    </Stack>
                </AppShell.Navbar>
            ) : ''}
            <AppShell.Main>
                <Routes>
                    <Route path="/sign-up" element={<SignUpForm/>}/>
                    <Route path="/sign-in" element={<LogInForm/>}/>
                    <Route path="/recover-password" element={<PasswordRecoveryForm/>}/>
                    <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>}/>
                    <Route path="/devices" element={<ProtectedRoute><Devices/></ProtectedRoute>}/>
                    <Route path="/locations" element={<ProtectedRoute><Locations/></ProtectedRoute>}/>
                </Routes>
            </AppShell.Main>
        </AppShell>
    );
};
