import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { Loader } from '@mantine/core';

// @ts-ignore
const ProtectedRoute = ({ children }) => {
    const { user, isLoading } = useAuth();

    if (isLoading) {
        return <Loader color="blue" />;
    } else {
        if (user) {
            return children;
        } else {
            return <Navigate to="sign-in" />;
        }
    }
};

export default ProtectedRoute;
