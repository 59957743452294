import React, { ReactNode } from 'react';
import { Container, Flex, Paper } from '@mantine/core';

interface AuthLayoutProps {
    children: ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
    return (
        <Container size="xs" px="xs" style={{ height: '100vh' }}>
            <Flex align="center" justify="center" style={{ height: '100%' }}>
                <Paper radius="md" p="xl" withBorder style={{ width: '100%', maxWidth: 400 }}>
                    {children}
                </Paper>
            </Flex>
        </Container>
    );
};

export default AuthLayout;
